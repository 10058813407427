export const environment = {
    production: false,
    siteKey: '6Lcoh_EpAAAAACReAepd-Ep8pmSBUYtYDw5R4_Xc',
    validateURL: 'https://docintake-validation-service-srq36lsisa-uk.a.run.app/',
    uploadURL:'https://docintake-single-doc-service-srq36lsisa-uk.a.run.app',
    submissionUrl: 'https://docintake-submission-service-srq36lsisa-uk.a.run.app',
    domainName: "eaudit",

    //per submssion
    maxFileCount :"50",
    maxFileSizeMB:"100",
    maxTotalSizeMB:"1400",
	fileSupported: "png,jpg,jpeg,docx,doc,txt,pdf,xls,xlsx,png,gif,mp4,mp3,zip",
	
	
	// Currently files size of 20 MB or less is considered small
    perUploadMaxFileCount:"10",
    perUploadMaxSmallFileCnt : "45",
    perUploadMaxSmallFileSizeMB : "20",
    perUploadMaxSmallFileTotalSizeMB : "900",
	
	// Files size over 20 MB is considered large
    perUploadMaxLargeFileCnt : "1",
    perUploadMaxLargeSizeMB :"100",
	perUploadmaxLargeFileTotalSizeMB : "500"
}